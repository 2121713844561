<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff" v-loading="pageLoading" >
		<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 font-color-000">我的车辆</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>
				<el-form-item label="类型" prop="carToolType">
					<el-select v-model="addCarForm.carToolType" clearable placeholder="请选择" >
						<el-option v-for="item in carToolList" :key="item.id"  @click.native="CarToolTypechange(item)"
							:label="item.fTransportToolType" :value="item.fTransportToolTypeID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车号/船号" prop="fPlateNumber">
					<el-input v-model.trim="addCarForm.fPlateNumber" placeholder="请输入车号/船号" @blur="blurInput"></el-input>
				</el-form-item>
				<el-form-item label="牌照颜色" prop="TransportToolPlateColor">
					<el-select v-model="addCarForm.TransportToolPlateColor" clearable placeholder="请选择">
						<el-option v-for="item in TransportToolPlateColorList" :key="item.id" 
							:label="item.fTransportToolPlateColor" :value="item.fTransportToolPlateColorID">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="司机" prop="fLinkMan">
					<el-input v-model="addCarForm.fLinkMan" placeholder="请输入司机姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="fLinkManTelCode">
					<el-input v-model="addCarForm.fLinkManTelCode" placeholder="请输入电话号码"></el-input>
				</el-form-item>
				<el-form-item label="载货量/净重(吨)" prop="fMaximumLoad">
					<el-input v-model="addCarForm.fMaximumLoad" type="number" placeholder="请输入载货量/净重"></el-input>
				</el-form-item>
				<el-form-item label="分组调整" prop='carGroupType'>
					<el-select filterable allow-create default-first-option :filter-method="getAddType" v-model="addCarForm.carGroupType" clearable placeholder="请选择">
						<el-option v-for="item in cargrouplList" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
					<div class="font-size12 font-color-FF9900">
						请选择需要分配的车辆分组或输入新的分组。
					</div>
				</el-form-item>
				<!-- <el-form-item v-if="addCarForm.stationType != ''" class="tips">
					
				</el-form-item> -->
				<el-form-item>
					<!-- <div class="comBtn font-color-fff text-center border-theme radius2 bg-fff color-theme margin-r-8" @click="resetForm('addCarForm')">取消</div> -->
					<el-button type="primary" class="bg-theme" @click="submitForm('addCarForm')"  :disabled="disabledAddCar" :loading="disabledAddCar">保存</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			var checkMaximum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('重量不能为空'));
				}
				setTimeout(() => {
					if (value < 1) {
						callback(new Error('重量必须大于0'));
					} else {
						callback();
					}

				}, 1000);
			};
      let validateInput = (rule, value, callback) => {
        if(this.selectval.fTransportToolClassID == 1 ){
          //这里是双判断 原因是 我要截取第一个字是交警12123的汉字
          console.log( /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0)),'測試')
         if(value.length >= this.carrule+1 && this.isVehicleNumber(value) && /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}$/.test(value.charAt(0))){
          callback();
         }else{
          callback(new Error("请输入正确的车牌号，除首位汉字外，车牌号只能包含数字或字母(不能包含字母I和O)"));
         }
        } else {
				if (!this.checkSpecialKey(value)) {
					callback(new Error("车船号只能输入汉字、数字和字母！"));
				} else {
					callback();
				}
        }
			};
			return {
				disabledAddCar: false,
        carrule:null,
        selectval:{},
				pageLoading: false,
				pageTitle: "新增车辆信息",
				//fTransportToolID:'',//编辑车辆的车辆id
				TransportToolPlateColorList:[],
				carToolList: [],
				cargrouplList:[],//车辆分组的类别
				addCarForm: {
					carGroupType:'',//分组名
					carToolType: '', //类型
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					fLinkManTelCode: '',
					fMaximumLoad: '',
					TransportToolPlateColor:''
				},
				rules: {
					
					carToolType: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}],
					
					TransportToolPlateColor: [{
						required: true,
						message: '请选择颜色',
						trigger: 'blur'
					}],
					fPlateNumber: [{
							required: true,
							message: '请输入车号/船号',
							trigger: 'change'
						},
						{
							validator: validateInput,
							trigger: 'blur'
						}
					],
					fLinkMan: [{
						required: true,
						message: '请输入司机姓名',
						trigger: 'change'
					}],
					fLinkManTelCode: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请输入正确的手机号码'));
							} else {
								let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
								if (phoneNumberRul.test(value)) {
									callback();
								} else {
									callback(new Error('请输入正确的手机号码'));
								}
							}
						},
						trigger: 'blur'
					}],
					fMaximumLoad: [{
						validator: checkMaximum,
						required: true,
						trigger: 'blur'
					}],
				},
				backEditList: {},
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		components: {},
		created() {
			this.pageTitle = this.$route.query.pageType;
			this.getTransportToolType();
			this.getTransportToolcolor();
      this.getcarrule()
			this.getgroupType();
		},
		methods: {
      //车辆号码验证长度
      getcarrule(){
				this.ApiRequestPost('/api/mall/ebbase/para-value/get-plate-number-min-length', {}).then(
					result => {
						this.carrule= result
            console.log(this.carrule,'车辆验证长度')
            if (this.pageTitle == "编辑车辆信息") {
              this.getCarEdit()
            }
					},
					rej => {}
				);
      },
      CarToolTypechange(val){
       this.selectval=val
      },
      isVehicleNumber (vehicleNumber) {
       let answer = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领学警港澳][0-9a-hj-np-zA-HJ-NP-Z]{5,}$/.test(vehicleNumber)
       console.log(answer)
       return answer
      },
			//特殊字符验证
			checkSpecialKey(str) {
				let specialKey = "[`~!@#$^&*()=|{}':;',\\[\\].·<>/?~！@#￥……&*（）——_|{}【】‘；：”“'。，、？-+/*<>《》]";
				for (let i = 0; i < str.length; i++) {
					if (specialKey.indexOf(str.substr(i, 1)) != -1) {
						return false;
					}
				}
				return true;
			},
			// 车船号 小写字符转大写
			blurInput() {
				this.addCarForm.fPlateNumber = this.checkStrWord(this.addCarForm.fPlateNumber)
			},
			checkStrWord(str) {
				if (str != '' && str != null) {
					// 转字母大写
					str = str.toUpperCase();
					// 去除空格 弃用
					str = str.replace(/\s+/g, "");
					return str;
				}
			},
			//通过车辆id获取修改数据
			getCarEdit() {
				this.addCarForm={
					carGroupType:'',//分组名
					carToolType: '', //类型
					fPlateNumber: '', //车号
					fLinkMan: '', //司机
					fLinkManTelCode: '',
					fMaximumLoad: '',
					TransportToolPlateColor:''
				},
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get', {
					id: this.$route.query.carToolID
				}).then(
					result => {
            console.log(result.obj.fTransportToolType)
						this.backEditList = result.obj;
						//回显数据
						this.backEditList.fTransportToolID = this.backEditList.fTransportToolID
						this.addCarForm.carToolType = this.backEditList.fTransportToolTypeID
						this.addCarForm.fMaximumLoad = this.backEditList.fMaximumLoad
						this.addCarForm.fPlateNumber = this.backEditList.fPlateNumber
						this.addCarForm.fLinkMan = this.backEditList.fLinkMan
						this.addCarForm.fLinkManTelCode = this.backEditList.fLinkManTelCode
						this.addCarForm.carGroupType = this.backEditList.fGroupName
						this.addCarForm.TransportToolPlateColor = this.backEditList.fTransportToolPlateColorID
            console.log(this.carrule,'yanz',this.addCarForm.carGroupType,this.carToolList)
            //问题出在编辑未调取下拉数据 没有选择选中的数据
            this.carToolList.forEach(item=>{
              if(result.obj.fTransportToolType == item.fTransportToolType){
                this.selectval = item
              }
            })
					},
					rej => {}
				);
			},
			//查询所有启用运输工具类型
			getTransportToolType() {
				this.ApiRequestPost('api/mall/ebbase/transportTool-Type/get-onstatus-list', {}).then(
					result => {
						// console.log(result)
						// let carType = [];
						// result.obj.reslist.forEach(function(val) {
						// 	carType.push({"id":val.value,"fGroupName": val.key})
						// })
						this.carToolList = result.obj.items;
					},
					rej => {}
				);
			},
			//查询所有启用颜色
			getTransportToolcolor() {
				this.ApiRequestPost('api/mall/ebbase/transportToolPlate-color/get-enable-list', {}).then(
					result => {
						// console.log(result)
						// let carType = [];
						// result.obj.reslist.forEach(function(val) {
						// 	carType.push({"id":val.value,"fGroupName": val.key})
						// })
						this.TransportToolPlateColorList = result.obj.items;
						if(this.pageTitle == '新增车辆信息'){
							if(result.obj.items.length !=0){
								result.obj.items.forEach((item,index)=>{
									if(item.fTransportToolPlateColor ==='黄色'){
										this.addCarForm.TransportToolPlateColor=item.fTransportToolPlateColorID
									}
								})
							}
						}
						
					},
					rej => {}
				);
			},
			//查询所有的车辆分组类别
			getgroupType() {
				this.ApiRequestPost('api/mall/ebcustomer/transporttool/get-list-groupName', {}).then(
					result => {
						console.log(result)
						console.log('分组信息列表===================',result)
						this.cargrouplList = result.obj.reslist;
						// if(this.addCarForm.carToolType == ""){
						// 	this.addCarForm.carToolType = this.carToolList[0].fTransportToolTypeID
						// }
					},
					rej => {}
				);
			},
			getAddType(e){
				this.addCarForm.carGroupType = e
				//输入框的值发生改变时触发
				console.log(this.addCarForm.carGroupType)
			},
			submitForm(formName) {
				this.disabledAddCar = true
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.pageTitle == "编辑车辆信息") {
							console.log(this.addCarForm.carGroupType)
							this.pageLoading = true;
							this.ApiRequestPut('api/mall/ebcustomer/transporttool/update', {
								fGroupName:this.addCarForm.carGroupType,
								fTransportToolTypeID: this.addCarForm.carToolType,
								fTransportToolPlateColorID: this.addCarForm.TransportToolPlateColor,
								fMaximumLoad: this.addCarForm.fMaximumLoad,
								fPlateNumber: this.addCarForm.fPlateNumber,
								fLinkMan: this.addCarForm.fLinkMan,
								fLinkManTelCode: this.addCarForm.fLinkManTelCode,
								fTransportToolID: this.backEditList.fTransportToolID,
								fStatus: this.backEditList.fStatus,
								fRemark: this.backEditList.fRemark,
								frv: this.backEditList.frv,
								rowStatus: this.backEditList.rowStatus
							}).then(
								result => {
									this.disabledAddCar = false
                  this.$nextTick(() => {
                    //保存后清除验证
                   this.$refs.addCarForm && this.$refs.addCarForm.resetFields()
                 })
									console.log(result)
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyVehicle',
										query: '',
									});

								},
								rej => {
									this.disabledAddCar = false
									this.pageLoading = false;
								}
							);

						} else if (this.pageTitle == "新增车辆信息") {
							console.log(this.addCarForm.carGroupType)
							this.pageLoading = true;
							this.disabledAddCar = false
							this.ApiRequestPost('api/mall/ebcustomer/transporttool/create', {
								fGroupName:this.addCarForm.carGroupType,
								fTransportToolTypeID: this.addCarForm.carToolType,
								fMaximumLoad: this.addCarForm.fMaximumLoad,
								fPlateNumber: this.addCarForm.fPlateNumber,
								fLinkMan: this.addCarForm.fLinkMan,
								fLinkManTelCode: this.addCarForm.fLinkManTelCode,
								fTransportToolPlateColorID: this.addCarForm.TransportToolPlateColor,
								fStatus: 0,
								fRemark: ""
							}).then(
								result => {
									this.disabledAddCar = false
                  this.$nextTick(() => {
                      //保存后清除验证
                   this.$refs.addCarForm && this.$refs.addCarForm.resetFields()
                 })
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyVehicle',
										query: '',
									});

								},
								rej => {
									this.disabledAddCar = false
									this.pageLoading = false;
								}
							);

						}

					} else {
						this.disabledAddCar = false
						//console.log('error submit!!');
						return false;
					}
				});
			},
			/* resetForm(formName) {
				//this.$refs[formName].resetFields();
				this.$router.back();

			} */

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		padding: 8px 14px;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 40px;
	}

	/* 按钮 */
	.comBtn {
		width: 160px;
		height: 38px;
		line-height: 38px;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
	}
</style>
